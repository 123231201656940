<template>
  <v-main>
  <v-container id="pp-main">
    <v-container style="height: 2vh"></v-container>
    <v-row>
      <v-card flat>
        <v-card-title>概要</v-card-title>
        <v-card-text>
          本方針は、R1 TECHNOLOGIES（以下「私たち」*といいます。）が提供するサービス（関連する付帯サービス、アプリケーション、ツール等を含み、以下「サービス」といいます。）をお客様がご利用になる場合に適用されます。
          本方針をよくお読みいただき、同意いただいた上で、サービスをご利用ください。お客様が会員サービスまたはサービスのご利用を開始した場合、私たちは、お客様の個人情報の取得、利用、提供および保管について説明した本方針の内容をお客様がご理解いただいたものとみなします。      
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>取得する個人情報</v-card-title>
        <v-card-text>
          私たちは、後記「個人情報の利用目的」に定める目的を達成するため、以下に定めるお客様の個人情報を取得します。私たちが取得するお客様の個人情報には、お客様から直接ご提供いただいた情報およびお客様のサービスのご利用に関して取得する情報が含まれます。
          <ol class="my-5">
            <li class="my-2 subtitle-1 font-weight-bold">メールアドレス(iOSバージョンご利用の場合)</li>
            アカウントへのログイン機能を提供するため、サービス利用開始時に収集されます。
            <li class="my-2 subtitle-1 font-weight-bold">アカウントID(iOSバージョンご利用の場合)</li>
            アカウントへのログイン機能を提供するため、サービス利用開始時に収集されます。
            <li class="my-2 subtitle-1 font-weight-bold">LINEアカウントID(LINEバージョンご利用の場合)</li>
            アカウントへのログイン機能を提供するため、サービス利用開始時に収集されます。データベースには暗号化された状態で保持されます。
            <li class="my-2 subtitle-1 font-weight-bold">詳細な位置情報</li>
            お客様がアプリケーションの機能(収量・バイオマス推定機能)を利用する際に、ターゲットとする圃場の位置情報を管理するために自動で収集されます。
            <li class="my-2 subtitle-1 font-weight-bold">収量・生育量の情報</li>
            お客様がアプリケーションの機能(収量・バイオマス推定機能)を利用する際に、ターゲットとする圃場の収量・バイオマス情報をサーバにて管理するために自動で収集されます。
            <li class="my-2 subtitle-1 font-weight-bold">撮影されたイネの画像</li>
            お客様がアプリケーションの機能(収量・バイオマス推定機能)を利用する際に、ターゲットとする画像をサーバにて管理するために自動で収集されます。
            <li class="my-2 subtitle-1 font-weight-bold">その他の情報</li>
            お客様から私たちへのお問い合わせ・ご連絡等に関する情報等
          </ol>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>個人情報の利用目的</v-card-title>
        <v-card-text>
          詳細な個人情報の利用目的は、以下に定めるとおりとし、これらに関連する目的を含むものとします。
          <ol class="my-5">
            <li class="my-2 subtitle-1 font-weight-bold">ログイン機能提供のため</li>
            アカウントへのログイン機能をR1 TECHNOLOGIESが提供するため。
            <li class="my-2 subtitle-1 font-weight-bold">サービスの改善および研究開発のため</li>
            R1 TECHNOLOGIESの新規サービスの開発および既存サービスのユーザビリティ向上のため、国内外の開発チームの開発者・研究者がデータにアクセスする場合があります。
            <li class="my-2 subtitle-1 font-weight-bold">データの受託分析・およびシステム開発業務の実施のため</li>
            R1 TECHNOLOGIESが第三者から依頼されるデータの受託分析およびシステム開発業務を実施するために、取得されたデータを加工し、個人の特定ができない形にした上で、利用する場合があります。
            <li class="my-2 subtitle-1 font-weight-bold">本サービスに関するご請求とその確認をするため</li>
            <li class="my-2 subtitle-1 font-weight-bold">本サービスの利用状況等を調査・分析するため</li>
            <li class="my-2 subtitle-1 font-weight-bold">サービス運営上のトラブルの解決のため</li>
            <li class="my-2 subtitle-1 font-weight-bold">サービスの不正利用防止や安全性の確保のため</li>
            <li class="my-2 subtitle-1 font-weight-bold">お問い合わせ対応及びお客様へのご連絡のため</li>            
          </ol>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>第三者提供について</v-card-title>
        <v-card-text>
          私たちは、研究機関やデータ分析・システム開発等を行う第三者に対して、位置情報及び収量・生育量の情報を、統計解析の研究及び関連するシステム開発のために提供することがあります。
          なお、提供する際には、情報の取得者や位置情報を加工して、提供先においてそれ自体では個人が特定できないようにいたします。
          上記の提供先には、外国にある第三者が含まれる可能性があります。
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>安全管理措置について</v-card-title>
        <v-card-text>
          私たちは、その取り扱う個人情報の漏えい、滅失又はき損の防止その他個人情報の安全管理のために、必要かつ適切な措置を講じます。私たちが講じる安全管理措置には、次に掲げる事項が含まれます。
          <ol class="my-5">
            <li class="my-2 subtitle-1 font-weight-bold">内部規律の整備</li>
            個人情報の取得、利用、提供、廃棄といった段階ごとに、個人情報取扱方法や担当者及びその任務等について規程を策定し、定期的に見直しを実施するなどの規律を整備しています。
            <li class="my-2 subtitle-1 font-weight-bold">組織体制の整備</li>
            私たちにおける個人情報の取扱いのすべてを監督する個人情報保護責任者を設置し、従業員が漏えい等を把握した場合、速やかに個人情報保護責任者に報告・連絡するなどの体制を整備しています。
            <li class="my-2 subtitle-1 font-weight-bold">定期点検・監査</li>
            個人情報の取扱いについて、定期的な自己点検、他部署監査、外部主体監査の実施等の措置を講じています。
            <li class="my-2 subtitle-1 font-weight-bold">不正アクセス等の防止</li>
            外部からの不正アクセス等から保護する仕組みを導入するなどの措置を講じています。
            <li class="my-2 subtitle-1 font-weight-bold">外的環境の把握</li>
            外国で個人データを取り扱っている場合の外国の個人情報保護制度を適時に把握し、対応できるような体制を整備しています。
          </ol>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>本方針の変更および改正について</v-card-title>
        <v-card-text>
          私たちは、本方針を、法令変更への対応の必要性および事業上の必要性等に応じて、随時、変更および改正（以下「変更等」といいます。）する場合があり、当該変更等について、このウェブページ上に掲載します。お客様は、このウェブページ上に掲載される変更等の後の最新の本方針の内容を十分にご確認ください。
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>開示等の請求について</v-card-title>
        <v-card-text>
          私たちは、ご本人であることを確認させていただいたうえで、個人情報保護法に基づく開示等の請求（利用目的の通知、開示、訂正・追加・削除、利用の停止・第三者提供の停止の請求をいいます。）に合理的な範囲で対応します。開示等の請求を行う場合は、以下のメールアドレスまでご連絡ください。
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>お問合わせ</v-card-title>
        <v-card-text>
          本方針またはお客様の個人情報の取扱いについて、ご質問、苦情の申出またはご懸念がある場合には、<a href="contact.r1.technologies@gmail.com">contact.r1.technologies@gmail.com</a> にお問い合わせください。
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>    
  </v-main>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {
  },
}
</script>

<style lang="css">
#pp-main {
  margin-bottom: 10vh;
}
</style>
